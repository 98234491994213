<template>
    <div class="saleteam-area">
        <div class="teams" v-for="(fav,index) in favourites" :key="index">
            <div class="team-img">
                <router-link :to="fav.user.name">
                    <img v-if="fav.user.picture" class="img-fluid" :src="fav.user.picture" alt="" />
                    <img v-else class="img-fluid" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/profile/profile.svg" alt="" />
                </router-link>
            </div>
            <div class="team-title">
                <h3>{{fav.user.name}}</h3>
                <!-- <p class="mb-2" v-if>{{fav.user.city}}, {{fav.user.state}}</p> -->
                <!-- <div class="member-status text-success">
                    <span>Favourite</span>
                </div> -->
            </div>
        </div>
        <div class="teams w-100" v-if="favourites.length == 0">
            <div class="add-new d-block my-5">
                <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/employees/no-employee.svg" style="width: 100px">
                <h5 class="fw-bold">No Favourites</h5>
                <p>Favourite some business to show here</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { useToast } from "vue-toastification"
    import axios from 'axios'

    export default {
        setup () {
            const toast = useToast()
            return { toast }
        },
        data(){
            return{
                favourites: [],
            }
        },
        created() {
            this.getFavs()
        },
        methods: {
            getFavs(){
                let thiss = this
                axios.get('favourites/business/'+thiss.$storage.getStorageSync('business').active_business.id).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.favourites = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            }
        }
    }
</script>
<style scoped>
.teams{
    height: auto;
}
.team-title{
    border-bottom: none;
}
</style>