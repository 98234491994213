<template>
    <AppContainer>
        <Favourites/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import Favourites from '@/components/dashboard/business/Favourites.vue'
export default {
  components: {
    AppContainer,
    Favourites
  }
}
</script>